import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";
import logo from '../assets/images/logo.png';
import sound from '../assets/sounds/Single Bee Buzzing 2 - QuickSounds.com.mp3'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuIsActive: false,
      play: false,
      audio: new Audio(sound)
    }
  }

  togglePlay = () => {
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.state.audio.play() : this.state.audio.pause();
    });
  }

  toggleMobileMenu() {
    if (this.state.mobileMenuIsActive === true) {
      this.setState({ mobileMenuIsActive: false })
    } else {
      this.setState({ mobileMenuIsActive: true })
    }
  }

  navMenuCssClasses() {
    let isActive = this.state.mobileMenuIsActive === true ? "active" : "";

    if (this.props.isHamburgerDefault === true) {
      return `${isActive} hamburger default nav-menu`
    } else {
      return `${isActive} nav-menu`
    }
  }

  hamburgerCssClasses() {
    let isActive = this.state.mobileMenuIsActive === true ? "active" : "";

    if (this.props.isHamburgerDefault === true) {
      return `${isActive} hamburger default`
    } else {
      return `${isActive} hamburger`
    }
  }

  render() {
    return (
      <div className='header'>
        {/* Logo */}
        <Link to="/">
          <img src={logo} className="logo" alt="Website logo" onMouseOver={() => this.togglePlay()}
            onMouseLeave={() => this.togglePlay()} onClick={() => this.state.audio.pause()} />
        </Link>

        <nav className="navbar">
          <ul className={this.navMenuCssClasses()}>

            <li className="nav-item">
              <Link to="/" className="nav-link">Начало</Link>
            </li>

            <li className="nav-item">
              <Link to="/menu" className="nav-link">Меню</Link>
            </li>

            <li className="nav-item">
              <Link to="/testimonials" className="nav-link">Мнения</Link>
            </li>

            <li className="nav-item">
              <Link to="/contacts" className="nav-link">Контакти</Link>
            </li>
          </ul>

          <div className={this.hamburgerCssClasses()}
            onClick={() => this.toggleMobileMenu()}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </div>

    )
  }

}

export default Header;