import './Home.css';
import React from 'react';
import Header from '../../components/Header';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      bannerImages: [
        {
          imageUrl: '/images/banner-min.jpg',
          altText: 'Apricot Pie'
        },
        {
          imageUrl: '/images/tiramisu-min.jpg',
          altText: 'Tiramisu'

        },
        {
          imageUrl: '/images/medena_surce-min.jpg',
          altText: 'Honey cake'

        },
        {
          imageUrl: '/images/maskarpone_banani_krug-min.jpg',
          altText: 'Mascarpone cake'
        }
      ]
    }
  }

  plusSlides(n) {
    let newSlideIndex = this.state.slideIndex + n;

    if (newSlideIndex > this.state.bannerImages.length - 1) {
      this.setState({ slideIndex: 0 });
    } else if (newSlideIndex < 0) {
      this.setState({ slideIndex: this.state.bannerImages.length - 1 });
    } else {
      this.setState({ slideIndex: newSlideIndex });
    }
  }

  render() {
    return (
      <section className="slideshow">
        {/* Navigation bar */}
        <Header isHamburgerDefault={false}></Header>

        {/* <!-- Banner containing images --> */}
        <div className='slides'>
          {this.state.bannerImages.map((image, index) =>
            <div key={index} className={'slide' + (this.state.slideIndex === index ? ' active' : '')} >
              <img src={image.imageUrl} className="slide-image" alt={image.altText}/>
            </div>
          )}
        </div>

        {/* <!-- Next and previous buttons --> */}
        <div className="prev" onClick={() => this.plusSlides(-1)}>&#10094;</div>
        <div className="next" onClick={() => this.plusSlides(1)}>&#10095;</div>

        <div className="banner-text">
          <h1>Chubee Bakery</h1>
          <p>Life is what you bake of it!</p>
        </div>

        <div className="icons">
          <a href="https://www.facebook.com/chubeebakery/" target="_blank" rel="noreferrer"> <i className="fab fa-facebook"></i></a>
          <a href="https://instagram.com/chubeebakery?utm_medium=copy_link" target="_blank" rel="noreferrer"> <i
            className="fab fa-instagram"></i></a>
        </div>

      </section>
    );
  }

}

export default Home;  