import React from "react";

class TestimonialCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0
    }
  }

  plusSlides(n) {
    let newSlideIndex = this.state.slideIndex + n;
  
    if (newSlideIndex > this.props.testimonials.length - 1) {
      this.setState({ slideIndex: 0 });
    } else if (newSlideIndex < 0) {
      this.setState({ slideIndex: this.props.testimonials.length - 1 });
    } else {
      this.setState({ slideIndex: newSlideIndex });
    }
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.plusSlides(1),
      6000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return (
      <div className="testimonials" id="testimonials">
        <div className="wrap">

          <span id="left-arrow" className="arrow left fa fa-chevron-left"
            onClick={() => this.plusSlides(-1)} ></span>
          <span id="right-arrow" className="arrow right fa fa-chevron-right"
            onClick={() => this.plusSlides(1)} ></span>

          <div className="cont" id="testimonials-content">
            {this.props.testimonials.map((testimonial, index) => {
              return (
                <div key={index} className={index === this.state.slideIndex ? 'active' : ''}>
                  <h2>{testimonial.authorName}</h2>
                  <p>"{testimonial.testimonialText}"</p>
                  <h3>{testimonial.cakeName}</h3>
                </div>
              )
            })}
          </div>

          <ul className="dots" id="testimonials-dots">
            {this.props.testimonials.map((testimonial, index) => {
              return (
                <li key={index} className={index === this.state.slideIndex ? 'dot active' : 'dot'}></li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default TestimonialCarousel;