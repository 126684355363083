import React from 'react';
import Header from '../../components/Header';
import TestimonialCarousel from '../../components/TestimonialCarousel';
import './Testimonials.css';

class Testimonials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonials: [
        {
          authorName: 'Иван',
          testimonialText: 'За тортата никой не отказа допълнително, не им е тежко за нея, ама за допълнително пържоли и луканки не искаха.',
          cakeName: 'Торта "Наполеон"'
        },
        {
          authorName: 'Ива',
          testimonialText: 'Всички са възхитени от тортата и бонбоните! Тортата беше супер вкусна, прясна и сочна!',
          cakeName: 'Медена торта, бонбони с бял и розов шоколад'
        },
        {
          authorName: 'Виктория',
          testimonialText: `На всички много им хареса и не знам дали ще пробваме друга. Просто няма такава торта. Значи, мислех си,
          че морковената е топ, но просто това бижу е невероятно. Загубихте ме като клиент на другите торти и вкусове.
          За мен вече съществува само тази торта. Значи то какво повече да искаш - банан, шоколад и бисквити.`,
          cakeName: 'Класическа бисквитена торта'
        },
        {
          authorName: 'Даниела',
          testimonialText: `Чийзкейка беше разкошен! Благодаря ви! На другия ден остана едно парче.. лек, ефирен, ароматен и много
              вкусен. Надявам се пак да имаме поводи да опитаме и други ваши вкуснотийки.`,
          cakeName: 'Чийзкейк с лайм'
        },
        {
          authorName: 'Илия',
          testimonialText: 'Облизахме си пръстите, много вкусна торта, добре че бяха две, така ми остана и за днес.',
          cakeName: 'Френска селска'
        },
        {
          authorName: 'Биляна',
          testimonialText: 'Всичко беше много вкусно, прекрасно! Благодаря ❤️!',
          cakeName: 'Торта "Наполеон", бонбони "Червено кадифе" и кексчета'
        }
      ]
    }
  }

  render() {
    return (
      <section className="testimonials-page">
        {/* Navigation bar */}
        <Header isHamburgerDefault={false}></Header>

        <div className="hero-three">
          <img className="secondImage" src="images/napoleon_cql.jpg" alt="cookies" />
          <h2 className="testimonial-title">МНЕНИЯ</h2>
        </div>

        <TestimonialCarousel testimonials={this.state.testimonials}></TestimonialCarousel>

      </section>
    )
  }
}

export default Testimonials;