import "./Menu.css";
import Header from "../../components/Header";

function Menu() {
  return (
    <section className="menu-page">
      <Header isHamburgerDefault={false}></Header>

      <div className="hero-two">
        <img
          className="firstImage"
          src="/images/bluberry_cake-min.jpeg"
          alt="cake"
        />
        <h2 className="menu-title">МЕНЮ</h2>
      </div>

      <article className="menu-items">
      <div className="cake-menu-image">
          <img
            src="images/eklerova.JPG"
            className="img-all cake-image"
            alt="Cheesecake Lime"
          />
        </div>
        <div className="cakes-menu-items">
          <h1 className="cakes-title">Торти</h1>
          <h3 className="mini-cake-title">
            Цените са за стандартна торта от 12 парчета
          </h3>
          <br />
          <br />

          <h4 className="single-cake-title">Морковена торта</h4>
          <p className="cake-description">
            Нашата не е като от вица за Зайо Байо - 60лв
          </p>
          <br />

          <h4 className="single-cake-title">Наполеон</h4>
          <p className="cake-description">
            Многолистно тесто, ароматен ванилов крем и много любов - 60лв
          </p>
          <br />

          <h4 className="single-cake-title">Маскарпоне и банани</h4>
          <p className="cake-description">
            {" "}
            Бестселър състояш се от медени блатове, маскарпоне, бял шоколад и
            банани за разкош - 80лв
          </p>
          <br />

          <h4 className="single-cake-title">Бисквитена с течен шоколад</h4>
          <p className="cake-description">
            Класика, с която няма как да сбъркаш - 60лв
          </p>
          <br />

          <h4 className="single-cake-title">Френска селска</h4>
          <p className="cake-description">Първата торта на Чъбито - 60лв</p>
          <br />

          <h4 className="single-cake-title">Тирамису</h4>
          <p className="cake-description">Вдигни ме на крака - 55лв</p>
          <br />

          <h4 className="single-cake-title">
            Класическа с домашен ванилов крем, банани и кокос
          </h4>
          <p className="cake-description">
            Любима торта на малки и големи - 60лв
          </p>
          <br />

          <h4 className="single-cake-title">
            Ванилова с малини, залята с шоколад
          </h4>
          <p className="cake-description">
            За специални поводи и не само - 75лв
          </p>
          <br />

          <h4 className="single-cake-title">Шоколадова с вишни</h4>
          <p className="cake-description">
            Любима торта на всички шокохолици - 70лв
          </p>
          <br />

          <h4 className="single-cake-title">Еклерова</h4>
          <p className="cake-description">Лека, въздушна, божествена - 60лв</p>
          <br />
        </div>

      </article>

      <article className="menu-items">
        <div className="cakes-menu-items">
          <h1 className="cakes-title">Чийзкейк селекция</h1>
          <h3 className="mini-cake-title">Цените са за 12 парчета</h3>
          <br />
          <br />

          <h4 className="single-cake-title">Чийзкейк с рикота и малини</h4>
          <p className="cake-description">
            За хората с капризно небце - кремообразна рикота, свежи малини и
            белгийски течен шоколад - 60лв
          </p>
          <br />

          <h4 className="single-cake-title">Чийзкейк с лайм</h4>
          <p className="cake-description">
            Свеж чийзкейк против кисело настроение - 50лв
          </p>
          <br />

          <h4 className="single-cake-title">
            Класически чийзкейк с плодове по избор
          </h4>
          <p className="cake-description">
            Понякога най-простите неща са най-вкусни - 50лв
          </p>
          <br />

          <h4 className="single-cake-title">
            Тройно печен чийзкейк с портокал от старите тефтери
          </h4>
          <p className="cake-description">
            Чийзкейкът, който плени сърцата ни преди много години в пекарната
            под операта - 65лв
          </p>
          <br />

          <h4 className="single-cake-title">
            Печен чийзкейк с шоколад и ягоди
          </h4>
          <p className="cake-description">
            Шоколад, шоколад и пак шоколад - 65лв
          </p>
          <br />

          <h4 className="single-cake-title">
            Печен чийзкейк с бял шоколад и малини
          </h4>
          <p className="cake-description">
            Малко по-нестандартен, но невероятно свеж и вкусен - 65лв
          </p>
          <br />
        </div>

        <div className="cake-menu-image">
          <img
            src="images/cheesecake_s_rikota.jpg"
            className="img-all cake-image"
            alt="Cheesecake With Ricotta Cheese"
          />
        </div>
      </article>


      <article className="menu-items">
        <div className="chocolates-menu-image">
          <img
            src="images/Kekscheta_i_bonboni.jpg"
            className="img-all chocolates-image"
            alt="Chocolates"
          />
        </div>

        <div className="chocolates-menu-items">
          <h1 className="cakes-title">Бонбони, кексчета и кошнички</h1>
          <h3 className="mini-cake-title">
            Цените са за 1бр, минимална поръчка - 10бр
          </h3>
          <br />
          <br />

          <h4 className="single-cake-title">Рафаело</h4>
          <p className="cake-description">
            Кокосови бонбони с хрупкав бадем - 1,50лв/бр
          </p>
          <br />

          <h4 className="single-cake-title">Бисквитени</h4>
          <p className="cake-description">
            Плътни, ароматни и много вкусни - 1,50лв/бр
          </p>
          <br />

          <h4 className="single-cake-title">Червено кадифе</h4>
          <p className="cake-description">
            Почти като тортата, но с лимонена свежест - 2лв/бр
          </p>
          <br />

          <h4 className="single-cake-title">Фурми с фъстъчено масло</h4>
          <p className="cake-description">
            В крак с фитнес тенденциите - 2лв/бр
          </p>
          <br />

          <h4 className="single-cake-title">Здравословни с ядки</h4>
          <p className="cake-description">
            Сушени плодове, ядки и шоколад, какво повече му трябва на човек -
            2лв/бр
          </p>
          <br />

          <h4 className="single-cake-title">Протеинови с фъстъчено масло</h4>
          <p className="cake-description">За любителите на протеин - 2лв/бр</p>
          <br />

          <h4 className="single-cake-title">Кексчета</h4>
          <p className="cake-description">С бял шоколад и малини - 2,50лв/бр</p>
          <br />

          <h4 className="single-cake-title">Кошнички</h4>
          <p className="cake-description">
            С домашен ванилов крем и плодове по избор - 2,50лв/бр
          </p>
          <br />
        </div>
      </article>

      <article className="menu-items">
        <div className="tarts-menu-items">
          <h1 className="cakes-title">Погачи и питки</h1>
          <h3 className="mini-cake-title">Цена при запитване</h3>
        </div>
      </article>
    </section>
  );
}

export default Menu;
