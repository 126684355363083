import './Contacts.css'
import Header from '../../components/Header';

function Contacts() {
  return (
    <section class="contact">

      <Header isHamburgerDefault={true}></Header>

      <div class="content">
        <h2>Свържете се с нас</h2>
        <br />
        <p>Скъпи пчелички, ако желаете да си поръчате някое от нашите сладки изкушение, не се колебайте, а се свържете с
          нас. Може да го направите, като попълните формата за контакти на тази страница или чрез фейсбук или инстаграм
          страницата ни.</p>
      </div>
      <div class="container">
        <div class="contactInfo">
          <div class="box">
            <div class="icon"><i class="fas fa-map-marker-alt"></i></div>
            <div class="text">
              <h3>Адрес</h3>
              <p>София, България<br />квартал Драгалевци<br /></p>
            </div>
          </div>
          <div class="box">
            <div class="icon"><i class="fas fa-envelope"></i></div>
            <div class="text">
              <h3>Имейл</h3>
              <p>chubeebakery@gmail.com</p>
            </div>
          </div>
        </div>
        <div class="contactForm">
          <form>
            <h2>Направете запитване</h2>
            <div class="inputBox">
              <input type="text" name="" required="required" />
              <span>Име и фамилия</span>
            </div>
            <div class="inputBox">
              <input type="text" name="" required="required" />
              <span>Имейл</span>
            </div>
            <div class="inputBox">
              <textarea required="required"></textarea>
              <span>Вашето съобщение се помещава тук...</span>
            </div>
            <div class="inputBox">
              <input type="submit" name="" value="Изпрати" />
            </div>
          </form>

        </div>
      </div>
    </section>
  )
}

export default Contacts